<template>
	<div>
		<pui-datatable
			:modelName="model"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:isEditableRegistry="isEditableRegistry"
			:modelColumnDefs="modelColumnDefs"
			:modalDialog="modalDialog"
			:navigableDetail="false"
			readOnly
		></pui-datatable>
	</div>
</template>

<script>
import equipamientospartidaActions from './EquipamientospartidaActions';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'equipamientospartida-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data() {
		return {
			model: 'equipamientospartida',
			actions: equipamientospartidaActions.gridactions,
			modelColumnDefs: {}
		};
	},
	methods: {
		isEditableRegistry() {
			return false;
		}
	}
};
</script>
